import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

const Navigation = (props) => {
    let [cart, setCart] = useState(JSON.parse(localStorage.getItem('lCart')));

    useEffect(() => {
        window.addEventListener('storage', () => {
            setCart(JSON.parse(localStorage.getItem('lCart')))
        }, false);
    })


    return (
        <div>
            <div className='flex w-20 justify-between items-center'>
                <NavLink to="/cart">
                    <div className="flex relative">
                        <FontAwesomeIcon icon={solid('shopping-bag')} color='black'/>
                        {(cart && cart.length !== 0) &&
                        <div
                            className='bg-[#9E1FCD] absolute bottom-0 right-0 translate-y-1/2 translate-x-2/4 rounded-full w-4 h-4 text-center align-middle text-xs'>{cart.length}</div>}
                    </div>
                </NavLink>
                <NavLink to="/" onClick={props.logOut}> Log out</NavLink>
            </div>
        </div>
    );
};

export default Navigation;
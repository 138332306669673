import './App.css';
import {NavLink, Route, Router, Routes} from "react-router-dom";
import Home from "./Containers/Home/Home";
import Contact from "./Containers/Contact/Contact";
import logo from './logo.svg';
import React, {useEffect, useState} from "react";


import './scss/global.scss';
import ProductDetail from "./Containers/Product/ProductDetail/ProductDetail";
import Login from "./Containers/Login/Login";
import Register from "./Containers/Register/Register";
import {logout} from "./actions/auth";
import {useDispatch, useSelector} from "react-redux";
import {clearMessage} from "./actions/message";
import {history} from "./helpers/history";
import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";
import Cart from "./Containers/Cart/Cart";
import Navigation from "./Components/Navigation/Navigation";
import CheckOut from "./Containers/CheckOut/CheckOut";

const App = () => {
    const {user: currentUser} = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [activeProduct, setActiveProduct] = useState();
    const [isCheckout, setIsCheckout] = useState(false);


    useEffect(() => {
        history.listen((location) => {
            dispatch(clearMessage());
        });
    }, [dispatch]);
    const logOut = () => {
        dispatch(logout());
    };
    useEffect(() => {
        EventBus.on("logout", () => {
            logOut();
        });
        return () => {
            EventBus.remove("logout");
        };
    }, [currentUser, logOut]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <div>
                {isCheckout ? <div className="flex justify-center px-10 py-2 min-h-10 md:h-20 items-center  bg-white">
                    <img src={logo} alt=""/>
                </div> : <div className="flex justify-between px-10 py-2 min-h-10 md:h-20 items-center  bg-white">
                    <NavLink to="/">
                        <img src={logo} alt=""/>
                    </NavLink>

                    {currentUser && (<Navigation logOut={logOut}/>)}

                </div>}
                <div>
                    <Routes history={history}>
                        <Route path="/"
                               element={<Home setActiveProduct={setActiveProduct} activeProduct={activeProduct}
                                              setIsCheckout={setIsCheckout}/>}/>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/register" element={<Register/>}/>
                        <Route path="/cart" element={<Cart setIsCheckout={setIsCheckout}/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                        <Route path="/products/:productId" element={<ProductDetail activeProduct={activeProduct}/>}/>
                        <Route path="/checkout" element={<CheckOut setIsCheckout={setIsCheckout}/>}/>
                    </Routes>
                </div>
                <AuthVerify logOut={logOut}/>
            </div>
        </>
    )
        ;
}

export default App;
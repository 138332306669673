import React from 'react';
import classes from './Filter.module.scss'
import PropTypes from "prop-types";

const Filter = ({text, name, check, change}) => {

    const onChange = (e) => {
        change()
    };
    return (
        <div className={classes['filter__container']}>
            <input type="checkbox" className={classes['filter']} id={text} name={name}
                   checked={check}
                   onChange={onChange}/>
            <label htmlFor={text}>{text}</label>
        </div>
    );
};

Filter.defaultProps = {
    checked: false,
};
Filter.propTypes = {
    text: PropTypes.string.isRequired,
    name: PropTypes.string,
    value: PropTypes.bool,
    setState: PropTypes.func
};
export default Filter;
import React, {useEffect, useRef, useState} from 'react'
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import * as PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ToggleSwitch from "../../Components/ToggleSwitch/ToggleSwitch";
import UserService from "../../services/user.service";
import EventBus from "../../common/EventBus";
import log from "tailwindcss/lib/util/log";
import Button from "../../Components/Buttons/Button";
import {history} from "../../helpers/history";


FontAwesomeIcon.propTypes = {icon: PropTypes.any};
const Cart = (props) => {
    let [cart, setCart] = useState([]);
    let [localCart, setLocalCart] = useState(localStorage.getItem("lCart"));
    const cartId = JSON.parse(localStorage.getItem("cart")).length !== 0 ? JSON.parse(localStorage.getItem("cart"))[0].id : 0;
    const [update, setUpdate] = useState(false);
    const timer = useRef(null);
    const BeCart = JSON.parse(localStorage.getItem("cart")).length !== 0 ? JSON.parse(localStorage.getItem("cart"))[0] : [];

    useEffect(() => {
        props.setIsCheckout(false);
    }, [])

    useEffect(() => {
        if (update) {
            clearTimeout(timer.current)
            timer.current = setTimeout(() => {
                updateCart()
            }, 500);
        }
        setUpdate(false);

    }, [update])

    useEffect(() => {
        localCart = JSON.parse(localCart);
        if (localCart) {
            setCart(localCart);
        }
    }, [localCart]);

    const getLabelText = (type) => {
        switch (type) {
            case 'pdf':
                return 'PDF';
                break;
            case 'label':
                return 'Label only';
                break;
            case 'unfilled':
                return 'Label on structure (unfilled)';
                break;
            case 'filled':
                return 'Label on structure (filled)';
                break;
        }
    }

    const getDeliveryDate = () => {
        const date = new Date(BeCart.estimated_delivery)
        const formattedDate = date.toLocaleDateString("en-US", {
            day: "numeric",
            month: "long",
            year: "numeric"
        })
        return formattedDate
    }

    const updateCart = async () => {

        let cartCopy = [...cart];
        await UserService.modifyCart(cartId, cartCopy).then(
            response => {
                let local = [];
                localStorage.setItem("cart", `[${JSON.stringify(response.data.data)}]`);
                response.data.data.items.map(y => {
                    if (local.filter(copy => copy.product_id === y.product.id).length === 0 && y.quantity !== 0) {
                        local.push({
                            "product_id": y.product.id,
                            "product_name": y.product.name,
                            "cover_image": y.product.cover_image,
                            "package_types": [{
                                'type': y.package_type,
                                'quantity': y.quantity,
                                'eta': y.eta,
                                'price': y.price
                            }]
                        });
                    } else {
                        if (y.quantity !== 0) {
                            local.find(copy => copy.product_id === y.product.id).package_types.push({
                                'type': y.package_type,
                                'quantity': y.quantity,
                                'eta': y.eta,
                                'price': y.price
                            });
                        }
                    }
                    setCart(local)
                    localStorage.setItem("lCart", JSON.stringify(local))
                })
            },
            error => {
                console.log(error);
                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            });
        window.dispatchEvent(new Event("storage"))
    }

    const getCartItems = () => {
        return cart.map(item => {
                return <div className='border-b border-gray-300 grid lg:grid-cols-[1fr_4fr] gap-5'>
                    <div className="flex">
                        <img src={item.cover_image} className='!max-w-[80%] max-h-[250px] mx-auto my-auto py-6'/>
                    </div>

                    <div className="flex flex-col">
                        <div className="font-bold uppercase text-2xl my-3">{item.product_name}</div>

                        {item.package_types.map(row => <div>
                            <div
                                className='hidden lg:grid lg:grid-cols-7 lg:grid-flow-row justify-between items-center w-full gap-5 mb-5'>
                                <div className='font-bold uppercase col-span-3'>{getLabelText(row.type)

                                }</div>
                                {row.type === 'pdf' ?
                                    <div className={"toggle-switch small-switch"}>
                                        <input
                                            type="checkbox"
                                            name={row.type}
                                            className="toggle-switch-checkbox"
                                            id={row.id}
                                            defaultChecked
                                            onChange={(e) => {
                                                e.target.checked = false;
                                                row.quantity = 0;
                                                setUpdate(true)
                                            }}
                                        />
                                        <label className="toggle-switch-label" htmlFor={row.id}>
                                            <span className={"toggle-switch-inner"}/>
                                            <span className={"toggle-switch-switch"}/>
                                        </label>
                                    </div>
                                    :
                                    <input type="number" defaultValue={row.quantity} name={row.type}
                                           className='max-w-[82px]'
                                           onChange={(e) => {
                                               if (parseInt(e.target.value) < 999) {
                                                   row.quantity = parseInt(e.target.value);
                                                   setUpdate(true)
                                               }
                                           }}/>}
                                <div className="text-md">${row.price} / ea</div>
                                <strong className="text-md">${row.price * row.quantity}</strong>
                                <div className="ml-auto">
                                    <FontAwesomeIcon icon={solid('trash-can')} onClick={() => {
                                        row.quantity = 0;
                                        setUpdate(true)
                                    }} color='black'/>
                                </div>

                            </div>
                            <div
                                className='flex flex-wrap lg:hidden w-full  mb-5'>
                                <div className="flex w-full justify-between">
                                    <div className='font-bold uppercase'>{getLabelText(row.type)

                                    }</div>
                                    <FontAwesomeIcon icon={solid('trash-can')} onClick={() => {
                                        row.quantity = 0;
                                        setUpdate(true)
                                    }} color='black'/>
                                </div>
                                <div className="flex w-full justify-between">
                                    {row.type === 'pdf' ?
                                        <div className='block'>
                                            <div className={"toggle-switch small-switch"}>
                                                <input
                                                    type="checkbox"
                                                    name={row.type}
                                                    className="toggle-switch-checkbox"
                                                    id={row.id + item + 'mobile'}
                                                    defaultChecked
                                                    onChange={(e) => {
                                                        e.target.checked = false;
                                                        row.quantity = 0;
                                                        setUpdate(true)
                                                    }}
                                                />
                                                <label className="toggle-switch-label" htmlFor={row.id + item + 'mobile'}>
                                                    <span className={"toggle-switch-inner"}/>
                                                    <span className={"toggle-switch-switch"}/>
                                                </label>
                                            </div>
                                        </div>

                                        :
                                        <input type="number" defaultValue={row.quantity} name={row.type}
                                               className='max-w-[82px]'
                                               onChange={(e) => {
                                                   if (parseInt(e.target.value) < 999) {
                                                       row.quantity = parseInt(e.target.value);
                                                       setUpdate(true)
                                                   }
                                               }}/>}
                                    <div className="flex flex-wrap justify-end">
                                        <div className="w-full text-right">${row.price} / ea</div>
                                        <strong>
                                            <div className="text-md">${row.price * row.quantity}</div>
                                        </strong></div>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
            }
        )
    };

    return (
        <div className='px-5 md:px-10'>
            <div
                className='sm:flex flex-col justify-around  py-5 md:grid md:grid-cols-[3fr_2fr] gap-10'>
                <div className="flex flex-col mt-20">
                    <div className="text-4xl uppercase font-bold mb-5"> Shopping cart</div>
                    {BeCart.length === 0 ? 'Your cart is empty!' : getCartItems()}
                    {}
                </div>
                {BeCart.length === 0 ? '' : <div className="flex flex-col mt-20">
                    <div className="text-4xl uppercase font-bold pb-10 border-gray-300 border-b">summary</div>
                    <div className="border-gray-300 border-b pb-5 flex flex-col">
                        <div className="flex justify-between mt-5">
                            <div className="text-md">
                                Subtotal:
                            </div>
                            <strong>${BeCart.total}</strong>
                        </div>
                        <div className="flex justify-between ">
                            <div className="text-md">
                                Estimated Delivery:
                            </div>
                            <div>{getDeliveryDate()}</div>
                        </div>
                    </div>
                    <div className="flex justify-between mt-5 text-xl text-[#EA3523]">
                        <strong>TOTAL</strong><strong>${BeCart.total}</strong>
                    </div>
                    <div className="flex justify-center mt-5">
                        <Button text={'proceed to checkout'} class={'rainbow'} click={() => {
                            updateCart().then(response => {
                                    UserService.checkOut(cartId).then(response => {
                                        history.push('/checkout');
                                        window.location.reload();
                                    })
                                }
                            )
                        }
                        }/>
                    </div>
                    <div className="flex justify-center mt-10">
                        * We will ship the entire order to you in one shipment, so whichever ship date is the farthest
                        out will determine the ETA.
                        If you require some products sooner, please create a separate order. Give us a chat if you have
                        any questions.
                    </div>
                </div>}


            </div>
        </div>

    )
}


export default Cart

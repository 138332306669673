import React from 'react';
import {Navigation, Pagination, Scrollbar, A11y} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import "./ProductSwiper.scss"
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';

const ProductSwiper = (props) => {

        const getSlides = () => {
            return props.images.map((image) =>
                <SwiperSlide key={Math.random()} className='grid justify-center'>
                    <img src={image} className='mw-100' alt="" data-bs-toggle="modal"
                         data-bs-target="#exampleModalFullscreen"/>
                </SwiperSlide>
            )
        }


        return (
            <div className='px-4'>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={50}
                    slidesPerView={1}
                    loop
                    allowTouchMove={false}
                    navigation={true}
                    preventClicks
                    preventClicksPropagation
                    breakpoints={{
                        1024: {
                            slidesPerView: `${props.desktopImageCount}`,
                            navigation: false
                        }
                    }}
                    className='swiper-product'
                >
                    {getSlides()}
                </Swiper>
            </div>
        );
    }
;

export default ProductSwiper;
import React, {useEffect, useState} from 'react';
import Button from "../../Components/Buttons/Button";
import UserService from "../../services/user.service";
import {history} from "../../helpers/history";
import classes from './DateSelect.module.scss';

const DateSelect = (props) => {
    useEffect(() => {
        props.setPhase(2)
    }, []);
    const [order] = useState(props.order);
    console.log(order)
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [deliveryDate, setDeliveryDate] = useState(props.order.estimated_delivery)

    const getDeliveryDate = () => {
        const date = new Date(order.estimated_delivery)
        const formattedDate = date.toLocaleDateString("en-US", {
            weekday: "long",
            day: "numeric",
            month: "long",
            year: "numeric"
        })
        return formattedDate
    }
    return (
        <div>
            <div className='mx-auto w-2/3 mt-8'>
                <h3 className='uppercase text-center text-2xl font-bold mb-20'>Current Delivery Date</h3>
                <div className="flex flex-col text-left">
                    <div className="flex w-full justify-between py-5 border-y">
                        <div className="flex items-center justify-center">
                            <label className={classes['container']}><strong>Current Delivery
                                Date:</strong> {getDeliveryDate()}
                                <input type="radio" id='default' name='date' className={classes['custom-radio']}
                                       defaultChecked={true}
                                       onChange={
                                           () => {
                                               setShowDatePicker(false);
                                               setDeliveryDate(order.estimated_delivery)
                                           }
                                       }/>
                                <span className={classes['checkmark']}/>
                            </label>

                        </div>
                        <div className="font-bold uppercase text-xs">free</div>
                    </div>
                    <div className="py-10 border-y">
                        <div className="flex w-full justify-between">
                            <div className="flex items-center justify-center">
                                <label className={classes['container']}><strong>Schedule Future
                                    Ship
                                    Date</strong>
                                    <input type="radio" id='custom' name='date' className=' rounded-full'
                                           required={true}
                                           onChange={() => setShowDatePicker(true)}/><span
                                        className={classes['checkmark']}/>
                                </label>
                            </div>
                            <div className="font-bold uppercase text-xs">free</div>
                        </div>
                        {showDatePicker &&
                        <input type="date" id='date' min={order.estimated_delivery}
                               className={classes['date'] + ' mt-6'}
                               onChange={(e) => {
                                   setDeliveryDate(e.target.value)
                               }}/>
                        }
                    </div>

                </div>
                <div className="flex flex-col items-center md:flex-row justify-between gap-5 mt-10">
                    <Button text='back to cart' class='ghost' click={() => {
                        history.push("/cart")
                        window.location.reload();
                    }}/>
                    <Button text='continue' click={() => {
                        UserService.setOrderDeliveryDate(props.orderID, deliveryDate).then(response => {
                            props.setOrder(response.data.data)
                            props.setActiveView('complete');
                        })
                    }} class='rainbow'/>
                </div>
            </div>
        </div>
    );
};

export default DateSelect;
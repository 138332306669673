import React from 'react';
import classes from './Button.module.scss'

const Button = (props) => {
    return (
        <button
            className={classes[`button`] + ' h-10 md:h-12 ' + classes[`${props.class}`]} onClick={() => {
            if (props.click) props.click()
        }} disabled={props.disabled}>{props.text}</button>
    );
};

export default Button;
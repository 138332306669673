import React, {useEffect, useState} from 'react';
import Button from "../../Components/Buttons/Button";
import Filter from "../../Components/Filter/Filter";
import './Home.scss';
import ProductCard from "../Product/ProductCard/ProductCard";
import ProductFilter from "../Product/ProductFilter/ProductFilter";
import {useSelector} from "react-redux";
import {Navigate} from "react-router-dom";
import UserService from "../../services/user.service";
import EventBus from "../../common/EventBus";

const Home = (props) => {
        const {isLoggedIn} = useSelector(state => state.auth);
        const [products, setProducts] = useState([]);
        let [limit, setLimit] = useState(8);
        const [filters, setFilters] = useState(null);
        const [filter, setFilter] = useState({});
        let [params, setParams] = useState();
        const [total, setTotal] = useState(0)

        useEffect(() => {
            props.setIsCheckout(false);
            window.scrollTo(0, 0);
            UserService.getProducts().then(
                response => {
                    setProducts(response.data.data);
                    setTotal(response.data.meta.total);
                },
                error => {
                    console.log(error)
                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                });
            UserService.getProductFilters().then(
                response => {
                    setFilters(response.data);
                },
                error => {
                    console.log(error)
                });
        }, []);

        function getMoreProducts(filter) {
            UserService.queryProducts(filter).then(
                response => {
                    setProducts(response.data.data);
                },
                error => {
                    console.log(error)
                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                });
        }

        const getProducts = () => {
            return products.map((x) => <ProductCard key={x.id} id={x.id} slug={x.slug} image={x.cover_image} name={x.name}
                                                    size={x.size + ' ' + x.uom} retailer='All'
                                                    variant1={x.variants[0].varianttype.variant.name}
                                                    variant2={x.variants[1].varianttype.variant.name}
                                                    setActiveProduct={props.setActiveProduct}/>)

        }

        if (!isLoggedIn
        ) {
            return <Navigate to="login"/>;
        }
        return (<div className='px-10'>
                <div
                    className='sm:flex flex-col justify-around  py-5 md:grid md:grid-cols-[1fr_4fr]'>
                    {filters &&
                    <ProductFilter params={params} setParams={setParams} setLimit={setLimit} filters={filters}
                                   setTotal={setTotal}
                                   setFilter={setFilter}
                                   setProducts={setProducts}/>}
                    <div className="products">
                        <div className="grid justify-center md:grid-cols-2 lg:grid-cols-4">
                            {getProducts()}
                        </div>
                        <div className="flex justify-center my-20">
                            {(products.length !== 0 && limit < total) &&
                            <Button text='Load More' class='rainbow' click={() => {
                                setLimit(limit += 8)
                                getMoreProducts({...filter, 'limit': limit})
                            }}/>}
                        </div>

                    </div>

                </div>

            </div>
        )
            ;
    }
;

export default Home;

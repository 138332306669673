import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://3dcolor-be.skvad.live/api/v1/";
const register = (first_name, last_name, email, password) => {
    return axios.post(API_URL + "register", {
        first_name,
        last_name,
        email,
        password,
    });
};
const login = (email, password, remember) => {
    setTimeout(() => {
        window.dispatchEvent(new Event("storage"))
    }, 1000)
    return axios
        .post(API_URL + "login", {
            email,
            password,
        })
        .then((response) => {
            if (response.data.success !== true) {
                return;
            }
            console.log(response.data)
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
                axios.get(API_URL + "user-data", {headers: authHeader()}).then((response) => {
                    localStorage.setItem("cart", JSON.stringify(response.data.data.carts));
                    let local = []
                    localStorage.setItem("lCart", JSON.stringify(local))
                    response.data.data.carts.map(x => x.items.map(y => {
                        if (local.filter(copy => copy.product_id === y.product.id).length === 0 && y.quantity !== 0) {
                            local.push({
                                "product_id": y.product.id,
                                "product_name": y.product.name,
                                "cover_image": y.product.cover_image,
                                "package_types": [{
                                    'id': y.id,
                                    'type': y.package_type,
                                    'quantity': y.quantity,
                                    'eta': y.eta,
                                    'price': y.price
                                }]
                            });
                        } else {
                            if (y.quantity !== 0) {
                                local.find(copy => copy.product_id === y.product.id).package_types.push({
                                    'id': y.id,
                                    'type': y.package_type,
                                    'quantity': y.quantity,
                                    'eta': y.eta,
                                    'price': y.price
                                });
                            }
                        }
                        localStorage.setItem("lCart", JSON.stringify(local))
                    }))
                })
            }
            return response.data;
        });

};

const verifyToken = (token) => {
    return axios.get(API_URL, {headers: {"Authorization": `Bearer ${token}`}})
        .then(res => {
            console.log(res.data);
            this.setState({
                items: res.data,  /*set response data in items array*/
                isLoaded: true,
                redirectToReferrer: false
            })
        })
};
const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("cart");
    localStorage.removeItem("lCart");
};
export default {
    register,
    login,
    logout,
    verifyToken
};


import React from 'react';
import {useNavigate} from 'react-router-dom'

const BackButton = () => {
    const navigate = useNavigate()

    return (
        <button onClick={() => navigate('/')}
                className='flex items-center my-4 text-lg text-purple-800 stroke-purple-800 hover:text-pink-700 hover:stroke-pink-700'>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24"
                 stroke="purple-800" strokeWidth={5}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18"/>
            </svg>
            <strong className='ml-3'> BACK</strong></button>
    )
}

export default BackButton;
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import Button from "../../Components/Buttons/Button";
import classes from "./ThankYou.module.scss";
import UserService from "../../services/user.service";

const ThankYou = (props) => {
    return (
        <div className={classes['page'] + ' flex items-center justify-center'}>
            <div className="">
                <div
                    className="text-xl alert text-white ">
                    <div className='flex flex-col items-center text-white'>
                        <div className="flex">
                            <FontAwesomeIcon
                                icon={solid("check")}
                                className='p-5 bg-orange-400 rounded-full w-fit font-bold mb-6 '/>
                        </div>

                        < h3
                            className='text-white uppercase font-bold mb-8'>Thank you for your order</h3>
                        <div className="text-sm text-center text-white mb-8 w-2/3">We're looking forward to working
                            together.
                            We
                            know that things change, so feel free to give us a call @ 513-221-6863 or a chat via this
                            app.
                        </div>
                        <Button text='order summary' click={() => {
                            UserService.getOrder(props.orderID).then(response => {
                                props.setOrder(response.data.data);
                                props.setActiveView('summary')
                            })

                        }} class='small'/>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ThankYou;
import React from 'react';
import classes from './ProductCard.module.scss'
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

const ProductCard = (props) => {
    return (
        <Link to={`/products/${props.slug}`} state={props.id} className='p-5'>
            <div className={classes[' product__card'] + ' border-box p-4 bg-white flex flex-col h-full m-2'}
                 id={props.id}>
                <div className='flex justify-center my-auto'>
                    <img className={classes['product-card__image'] + ' mb-2 max-h-[150px]'} src={props.image}
                         alt={props.name}/>
                </div>
                <div className=''>
                    <h2 className=' my-4 text-base md:text-xl font-bold'>{props.name}</h2>
                    <div className={classes[' description']}>
                        <p>{props.variant1}</p>
                        <p>{props.variant2}</p>
                        <p>{props.size}</p>
                        {props.retailer ? <p>Retailer: {props.retailer}</p> : ''}
                    </div>
                </div>

            </div>
        </Link>
    );
};

ProductCard.propTypes = {
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    name: PropTypes.string,
    variant1: PropTypes.string,
    variant2: PropTypes.string,
    retailer: PropTypes.string,
    slug: PropTypes.string.isRequired
};

export default ProductCard;
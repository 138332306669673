import React, {useEffect, useRef, useState} from "react"
import {Navigate, useLocation, useParams} from "react-router-dom";
import ToggleSwitch from "../../../Components/ToggleSwitch/ToggleSwitch";
import BackButton from "../../../Components/BackButton/BackButton";
import Button from "../../../Components/Buttons/Button";
import ProductSwiper from "../ProductSwiper/ProductSwiper";
import DetailsSlider from "../DetailsSlider/DetailsSlider";
import UserService from "../../../services/user.service";
import {useSelector} from "react-redux";
import EventBus from "../../../common/EventBus";

const ProductDetail = (props) => {
    let [cart, setCart] = useState([]);
    let localCart = localStorage.getItem("cart");
    let [lCart, setLCart] = useState([]);
    let sendCart = localStorage.getItem('lCart');
    const location = useLocation();
    const [productId, setProductId] = useState(location.state);
    const productSlug = useParams().productId;
    let existingItem = (sendCart !== null && JSON.parse(sendCart).find(cartItem => cartItem.product_id === productId) !== undefined) ? JSON.parse(sendCart).find(cartItem => cartItem.product_id === productId) : {}
    const [product, setProduct] = useState([]);
    const [isPDFChecked, setIsPDFChecked] = useState((existingItem.package_types && existingItem.package_types.find(type => type.type === 'pdf')) ? !!existingItem.package_types.find(type => type.type === 'pdf').quantity : false);
    const [labelSelect, setLabelSelect] = useState((existingItem.package_types && existingItem.package_types.find(type => type.type === 'label')) ? existingItem.package_types.find(type => type.type === 'label').quantity : 0);
    const [labelTier, setLabelTier] = useState(1);
    const [unfilledSelect, setUnfilledSelect] = useState((existingItem.package_types && existingItem.package_types.find(type => type.type === 'unfilled')) ? existingItem.package_types.find(type => type.type === 'unfilled').quantity : 0);
    const [unfilledTier, setUnfilledTier] = useState(1);
    const [filledSelect, setFilledSelect] = useState((existingItem.package_types && existingItem.package_types.find(type => type.type === 'filled')) ? existingItem.package_types.find(type => type.type === 'filled').quantity : 0);
    const [filledTier, setFilledTier] = useState(existingItem.filledTier ?? 1);
    const {isLoggedIn} = useSelector(state => state.auth);

    useEffect(() => {
        localCart = JSON.parse(localCart);
        if (localCart) {
            setCart(localCart)
        }
        sendCart = JSON.parse(sendCart)
        if (sendCart) {
            setLCart(sendCart)
        }
    }, []);

    useEffect(() => {
        let cartCopy = [...cart];
    }, [setCart])

    useEffect(() => {
        window.scrollTo(0, 0);
        UserService.getProductDetails(productSlug).then(
            response => {
                setProductId(response.data.data.id)
                setProduct(response.data.data);
            },
            error => {
                console.log(error);
                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            });
    }, []);
    useEffect(() => {
        if (product.prices) {
            if (labelSelect <= product.prices.tier1.top_quantity) setLabelTier(1);
            if ((product.prices.tier1.top_quantity < labelSelect) && (labelSelect <= product.prices.tier2.top_quantity)) setLabelTier(2);
            if (labelSelect > product.prices.tier2.top_quantity) setLabelTier(3);
        }

    }, [labelSelect, product]);

    useEffect(() => {
        if (product.prices) {
            if (unfilledSelect <= product.prices.tier1.top_quantity) setUnfilledTier(1);
            if ((product.prices.tier1.top_quantity < unfilledSelect) && (unfilledSelect <= product.prices.tier2.top_quantity)) setUnfilledTier(2);
            if (unfilledSelect > product.prices.tier2.top_quantity) setUnfilledTier(3);
        }
    }, [unfilledSelect, product]);

    useEffect(() => {
        if (product.prices) {
            if (filledSelect <= product.prices.tier1.top_quantity) setFilledTier(1);
            if ((product.prices.tier1.top_quantity < filledSelect) && (filledSelect <= product.prices.tier2.top_quantity)) setFilledTier(2);
            if (filledSelect > product.prices.tier2.top_quantity) setFilledTier(3);
        }
    }, [filledSelect, product]);
    const handleCartAdd = (e) => {
        e.preventDefault();
        let cartPackages = [];
        isPDFChecked && cartPackages.push({'type': 'pdf', 'quantity': isPDFChecked ? 1 : 0});
        labelSelect !== 0 && cartPackages.push({'type': 'label', 'quantity': labelSelect});
        unfilledSelect !== 0 && cartPackages.push({'type': 'unfilled', 'quantity': unfilledSelect});
        filledSelect !== 0 && cartPackages.push({'type': 'filled', 'quantity': filledSelect});
        let cartCopy = [];
        if (JSON.parse(localCart).length !== 0) {
            cartCopy = JSON.parse(sendCart).filter(cartItem => cartItem.product_id !== productId);
        }
        cartCopy.push({
            "product_id": product.id,
            "product_name": product.name,
            "cover_image": product.cover_image,
            "package_types": cartPackages
        })
        if (JSON.parse(localCart).length === 0) {
            UserService.addToCart(cartCopy).then(
                response => {
                    localStorage.setItem("cart", `[${JSON.stringify(response.data.data)}]`);
                    let local = []
                    response.data.data.items.map(y => {
                        if (local.filter(copy => copy.product_id === y.product.id).length === 0) {
                            local.push({
                                "product_id": y.product.id,
                                "product_name": y.product.name,
                                "cover_image": y.product.cover_image,
                                "package_types": [{
                                    'id': y.id,
                                    'type': y.package_type,
                                    'quantity': y.quantity,
                                    'eta': y.eta,
                                    'price': y.price
                                }]
                            });
                        } else {
                            local.find(copy => copy.product_id === y.product.id).package_types.push({
                                'id': y.id,
                                'type': y.package_type,
                                'quantity': y.quantity,
                                'eta': y.eta,
                                'price': y.price
                            });
                        }
                        localStorage.setItem("lCart", JSON.stringify(local))
                    })
                },
                error => {
                    console.log(error);
                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                });
        } else {
            UserService.modifyCart(JSON.parse(localCart)[0].id, cartCopy).then(
                response => {
                    localStorage.setItem("cart", `[${JSON.stringify(response.data.data)}]`);
                    let local = [];
                    response.data.data.items.map(y => {
                        if (local.filter(copy => copy.product_id === y.product.id).length === 0) {
                            local.push({
                                "product_id": y.product.id,
                                "product_name": y.product.name,
                                "cover_image": y.product.cover_image,
                                "package_types": [{
                                    'id': y.id,
                                    'type': y.package_type,
                                    'quantity': y.quantity,
                                    'eta': y.eta,
                                    'price': y.price
                                }]
                            });
                        } else {
                            local.find(copy => copy.product_id === y.product.id).package_types.push({
                                'id': y.id,
                                'type': y.package_type,
                                'quantity': y.quantity,
                                'eta': y.eta,
                                'price': y.price
                            });
                        }
                        localStorage.setItem("lCart", JSON.stringify(local))
                    })
                },
                error => {
                    console.log(error);
                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                });
        }
        setTimeout(() => {
            window.dispatchEvent(new Event("storage"))
        }, 500)
    };
    const onChangePDF = (e) => {
        setIsPDFChecked(!isPDFChecked)
    }

    const onChangeLabel = (e) => {
        let quantity = parseInt(e.target.value);
        setLabelSelect(quantity);
    }
    const onChangeFilled = (e) => {
        let filledQuantity = parseInt(e.target.value);
        setFilledSelect(filledQuantity);
    }
    const onChangeUnfilled = (e) => {
        let unfilledQuantity = parseInt(e.target.value);
        setUnfilledSelect(unfilledQuantity);
    }

    const getProductSwiper = () => {
        if (product.gallery) return <ProductSwiper images={product.gallery} desktopImageCount={2}/>
    }

    const getDetailsSlider = () => {
        if (product.gallery) return <DetailsSlider images={product.gallery}/>
    }

    const formatYmd = (days) => {
        const date = new Date();
        date.setDate(date.getDate() + days);
        const formattedDate = date.toLocaleDateString("en-US", {
            day: "numeric",
            month: "numeric",
            year: "numeric"
        })
        return formattedDate
    };
    const getLabelPrice = () => {
        switch (labelTier) {
            case 1:
                return product.prices.tier1.label_price;
            case 2:
                return product.prices.tier2.label_price;
            case 3:
                return product.prices.tier3.label_price;
        }
    };

    const getLabelEta = () => {
        switch (labelTier) {
            case 1:
                return formatYmd(product.prices.tier1.label_eta);
            case 2:
                return formatYmd(product.prices.tier2.label_eta);
            case 3:
                return formatYmd(product.prices.tier3.label_eta);
        }

    }

    const getUnfilledPrice = () => {
        switch (unfilledTier) {
            case 1:
                return product.prices.tier1.unfilled_price;
            case 2:
                return product.prices.tier2.unfilled_price;
            case 3:
                return product.prices.tier3.unfilled_price;
        }
    };

    const getUnfilledEta = () => {
        switch (unfilledTier) {
            case 1:
                return formatYmd(product.prices.tier1.unfilled_eta);
            case 2:
                return formatYmd(product.prices.tier2.unfilled_eta);
            case 3:
                return formatYmd(product.prices.tier3.unfilled_eta);
        }

    };

    const getFilledPrice = () => {
        switch (filledTier) {
            case 1:
                return product.prices.tier1.filled_price;
            case 2:
                return product.prices.tier2.filled_price;
            case 3:
                return product.prices.tier3.filled_price;
        }
    };

    const getFilledEta = () => {
        switch (filledTier) {
            case 1:
                return formatYmd(product.prices.tier1.filled_eta);
            case 2:
                return formatYmd(product.prices.tier2.filled_eta);
            case 3:
                return formatYmd(product.prices.tier3.filled_eta);
        }

    }

    const side = () => {
        if (product.name) return <div className="mt-5 lg:mt-0"><h1>{product.name}</h1>
            <p>{product.description}</p>
            <form onSubmit={handleCartAdd}>
                <div className="detail_group border-b-2 border-gray-300 py-4">
                    <div className="flex justify-between items-center my-4">
                        <h2>PDF</h2>
                        <div className="block">
                            <ToggleSwitch onChange={onChangePDF} setValue={setIsPDFChecked} small id={'1'}
                                          checked={isPDFChecked}/>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 md:flex md:flex-wrap justify-between">
                        <p className='font-bold uppercase col-span-2 mb-3 md:mb-0'>Delivery date and pricing</p>
                        <p className=''>{formatYmd(product.prices.pdf.eta)}</p>
                        <p className='font-bold text-right'>${product.prices.pdf.price} / ea</p>
                    </div>
                </div>

                <div className="detail_group border-b-2 border-gray-300 py-4">
                    <div className="flex justify-between items-center my-4">
                        <h2>label only</h2>
                        <input type='number' min="0" max={product.prices.tier3.top_quantity} min="0"
                               onChange={onChangeLabel}
                               name='label_only'
                               value={labelSelect}
                               className='w-2/12'/>
                    </div>
                    <div className="grid grid-cols-2 md:flex md:flex-wrap justify-between">
                        <p className='font-bold uppercase col-span-2'>Delivery date and pricing</p>
                        <p className=''>{getLabelEta()}</p>
                        <p className='font-bold text-right'>${getLabelPrice()} / ea</p>
                    </div>
                </div>

                <div className="detail_group border-b-2 border-gray-300 py-4">
                    <div className="flex justify-between items-center my-4">
                        <h2>Label on structure (unfilled)</h2>
                        <input type='number' min="0" max={product.prices.tier3.top_quantity} onChange={onChangeUnfilled}
                               name='label_only' value={unfilledSelect}
                               className='w-2/12'/>
                    </div>
                    <div className="grid grid-cols-2 md:flex md:flex-wrap justify-between">
                        <p className='font-bold uppercase col-span-2'>Delivery date and pricing</p>
                        <p className=''>{getUnfilledEta()}</p>
                        <p className='font-bold text-right'>${getUnfilledPrice()} / ea</p>
                    </div>
                </div>

                <div className="detail_group border-b-2 border-gray-300 py-4">
                    <div className="flex justify-between items-center my-4">
                        <h2>Label on structure (filled)</h2>
                        <input type='number' min="0" max={product.prices.tier3.top_quantity} onChange={onChangeFilled}
                               name='label_only' value={filledSelect}
                               className='w-2/12'/>
                    </div>
                    <div className="grid grid-cols-2 md:flex md:flex-wrap justify-between">
                        <p className='font-bold uppercase col-span-2'>Delivery date and pricing</p>
                        <p className=''>{getFilledEta()}</p>
                        <p className='font-bold text-right'>${getFilledPrice()} / ea</p>
                    </div>
                </div>
                <div className="flex w-full justify-center mt-10">
                    <Button text='add to cart' class='rainbow' click=''/>
                </div>
            </form>
        </div>
    }

    if (!isLoggedIn
    ) {
        return <Navigate to="/login"/>;
    }
    return (
        <div className='px-5 md:px-12'>
            <BackButton/>
            <div className="flex flex-col md:grid md:grid-cols-1 lg:grid-cols-3">
                <div className="col-span-2">
                    {getProductSwiper()}
                </div>
                {side()}
                <div
                    className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-hidden bg-white"
                    id="exampleModalFullscreen" tabIndex="-1" aria-labelledby="exampleModalFullscreenLabel"
                    data-bs-backdrop="static" data-bs-keyboard="false"
                    aria-hidden="true">
                    <div className="modal-dialog w-full relative pointer-events-none">
                        <div
                            className="modal-content border-none relative flex flex-col h-4/5 m-5 md:m-5 pointer-events-auto bg-gray-200">
                            <div
                                className="modal-header flex flex-shrink-0 relative items-center justify-between p-4  rounded-t-md">

                                <button type="button"
                                        className="btn-close box-content w-12 h-12 p-1 text-black text-3xl border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                                        data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body min-h-[650px] md:min-h-[900px]">
                                {getDetailsSlider()}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDetail
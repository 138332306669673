import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import './ToggleSwitch.scss';

const ToggleSwitch = ({id, name, checked, setValue, optionLabels, small, disabled}) => {
    let [isChecked, setIsChecked] = useState(checked);

    useEffect(() => {
        if (setValue) setValue(isChecked)
    }, [isChecked])
    const handleChange = () => {
        setIsChecked(!isChecked)
    }

    return (
        <div className={"toggle-switch" + (small ? " small-switch" : "")}>
            <input
                type="checkbox"
                name={name}
                className="toggle-switch-checkbox"
                id={id}
                checked={isChecked}
                onChange={handleChange}
                disabled={disabled}
            />
            {id ? (
                <label className="toggle-switch-label" htmlFor={id}>
            <span
                className={
                    disabled
                        ? "toggle-switch-inner toggle-switch-disabled"
                        : "toggle-switch-inner"
                }

            />
                    <span
                        className={
                            disabled
                                ? "toggle-switch-switch toggle-switch-disabled"
                                : "toggle-switch-switch"
                        }
                    />
                </label>
            ) : null}
        </div>
    );
}

ToggleSwitch.defaultProps = {
    optionLabels: ["No", "Yes"],
};

ToggleSwitch.propTypes = {
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    name: PropTypes.string,
    optionLabels: PropTypes.array,
    small: PropTypes.bool,
    disabled: PropTypes.bool
};

export default ToggleSwitch;
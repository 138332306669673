import React, {useEffect, useState} from 'react';
import UserService from "../../../services/user.service";
import EventBus from "../../../common/EventBus";
import classes from "../../../Components/Filter/Filter.module.scss";


const ProductFilter = (props) => {
        const initialState = props.filters;
        const [isActive, setIsActive] = useState(false);
        const [activeFilterArray] = useState([]);
        let [activeFilters, setActiveFilters] = useState(0);
        let [counter, setCounter] = useState(0);
        let [filter, setFilter] = useState({});

        useEffect(() => {
                if (window.innerWidth >= 768) {
                    console.log(activeFilters)
                    setIsActive(true);
                    window.addEventListener('resize', () => {
                        setIsActive(false);
                        if (window.innerWidth >= 768) {
                            setIsActive(true);
                        }
                    })
                }
            },
            []
        )

        useEffect(() => {
            let array = initialState.keys;
            if (array) setActiveFilters(array.filter(x => x === true).length);
        }, [activeFilterArray])

        useEffect(() => {
            UserService.queryProducts(filter).then(
                response => {
                    props.setProducts(response.data.data);
                    props.setTotal(response.data.meta.total);
                    props.setFilter(filter);
                    props.setLimit(8);
                },
                error => {
                    console.log(error)
                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                });
        }, [activeFilters]);

        const resetFilter = () => {
            setFilter({});
            props.setFilter({})
            getFilterGroups();
            setActiveFilters(0);
            setCounter(counter += 1)
        };

        const getFilters = (group) => {

            return (
                <div className='flex flex-row flex-wrap'
                     key={'groupwrapper' + group}>{Object.values(props.filters[group]).map((x) => <div
                    key={x.name}
                    className={classes['filter__container']}>
                    <input type="checkbox" className={classes['filter']} key={x.name + x.id} id={x.name}
                           name={group} value={x.id}
                           checked={
                               (x.is_attribute && 'attributes' in filter && group in filter.attributes && filter['attributes'][group].indexOf(parseFloat(x.id)) > -1) || (!(x.is_attribute) && group in filter && filter[group].indexOf(x.id) > -1)
                           }
                           onChange={(e) => {
                               {
                                   let filterArray = [];
                                   let local = filter;
                                   if (e.target.checked) {
                                       setActiveFilters(activeFilters += 1)

                                       if (x.is_attribute) {
                                           if (!('attributes' in local)) {
                                               local["attributes"] = {};
                                           }
                                           if (!(e.target.name in local.attributes)) {
                                               local['attributes'][e.target.name] = [parseFloat(e.target.value)]
                                           } else {
                                               filterArray = local['attributes'][e.target.name];
                                               filterArray.push(parseFloat(e.target.value));
                                               local['attributes'][e.target.name] = filterArray;
                                           }
                                       } else {
                                           if (!(e.target.name in local)) {
                                               local[e.target.name] = [parseFloat(e.target.value)];
                                           } else {
                                               filterArray = local[e.target.name];
                                               filterArray.push(parseFloat(e.target.value));
                                               local[e.target.name] = filterArray;
                                           }
                                       }
                                   } else {
                                       setActiveFilters(activeFilters -= 1);
                                       let index = 0;
                                       if (x.is_attribute) {
                                           index = local['attributes'][e.target.name].indexOf(parseFloat(x.id));
                                           if (index > -1) {
                                               local['attributes'][e.target.name].splice(index, 1);
                                           }
                                           if (local['attributes'][e.target.name].length === 0) {
                                               delete local.attributes[e.target.name]
                                           }
                                           if (Object.keys(local["attributes"]).length === 0) {
                                               delete local.attributes
                                           }
                                       } else {
                                           index = local[e.target.name].indexOf(parseFloat(x.id));
                                           if (index > -1) {
                                               local[e.target.name].splice(index, 1);
                                           }
                                           if (local[e.target.name].length === 0) {
                                               delete local[e.target.name]
                                           }
                                       }
                                       setFilter(local);
                                   }
                               }
                           }}/>
                    <label key={'label' + x.name} htmlFor={x.name}>{x.name}</label>
                </div>)}

                </div>);
        }


        const getFilterGroups = () => {
            return Object.keys(props.filters).map((x) =>
                <div className="filter__group" key={'group' + x + counter}>
                    <h2 className="filter__title" key={'title' + x}>{x}</h2>
                    {getFilters(x)}
                </div>)
        }


        return (
            <div className="accordion-item">
                <div
                    className={(isActive && 'border-b border-gray-300') + " md:hidden flex justify-between py-5"}>
                    <button className="accordion-title font-bold">
                        <h2 onClick={() => setIsActive(!isActive)}>{activeFilters > 0 ? 'Filters (' + activeFilters + ')' : 'Filters'}</h2>
                    </button>
                    {isActive ? <div className='flex items-center justify-between'>
                            <button className="filter-reset mr-4 text-purple-700 font-bold text-lg" onClick={() => {
                                resetFilter()
                            }}>CLEAR ALL
                            </button>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                 viewBox="0 0 24 24"
                                 stroke="currentColor" strokeWidth={2} onClick={() => setIsActive(!isActive)}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M5 15l7-7 7 7"/>
                            </svg>
                        </div> :
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                             viewBox="0 0 24 24"
                             stroke="currentColor" strokeWidth={2} onClick={() => setIsActive(!isActive)}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7"/>
                        </svg>

                    }
                </div>
                <div className="hidden md:flex justify-between border-b border-gray-300 py-5">
                    <button className="accordion-title font-bold">
                        <h2>{activeFilters > 0 ? 'Filters (' + activeFilters + ')' : 'Filters'}</h2>
                    </button>
                    {isActive && <div className='flex items-center justify-between'>
                        <button className="filter-reset mr-4 text-purple-700 font-bold text-lg" onClick={() => {
                            resetFilter()
                        }}>CLEAR ALL
                        </button>
                    </div>

                    }
                </div>
                {isActive && <div className="accordion-content">
                    <div className="filter flex flex-col">
                        <form action="https://3dcolor-be.skvad.live/api/v1/products" method="post">
                            {getFilterGroups()}
                        </form>

                    </div>
                </div>}
            </div>
        );
    }
;

export default ProductFilter;
import React, {useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import {isEmail} from "validator";
import {register} from "../../actions/auth";
import './Register.scss';
import Button from "../../Components/Buttons/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {history} from "../../helpers/history";

const required = (value) => {
    if (!value) {
        return (
            <div className="text-white absolute" role="alert">
                This field is required!
            </div>
        );
    }
};
const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="text-white absolute" role="alert">
                This is not a valid email.
            </div>
        );
    }
};
let passwordValue = '';
const vpassword = (value) => {
    passwordValue = value;
    if (value.length < 6) {
        return (
            <div className="text-white absolute" role="alert">
                The password must be at least 6 characters.
            </div>
        );
    }
};
const vpasswordConfirm = (value) => {
    if (value !== passwordValue) {
        return (
            <div className="text-white absolute" role="alert">
                The passwords are not matching.
            </div>
        );
    }
}

const Register = () => {
    const form = useRef();
    const checkBtn = useRef();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [passwordShown, setPasswordShown] = useState(false)
    const [passwordConfirmShown, setPasswordConfirmShown] = useState(false)
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [successful, setSuccessful] = useState(false);
    const {message} = useSelector(state => state.message);
    const dispatch = useDispatch();
    const onChangePasswordConfirmation = (e) => {
        const passwordConfirm = e.target.value;
        setPasswordConfirmation(passwordConfirm);
    };
    const onChangeFirstName = (e) => {
        const fname = e.target.value;
        setFirstName(fname);
    };
    const onChangeLastName = (e) => {
        const lname = e.target.value;
        setLastName(lname);
    };
    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };
    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };
    const handleRegister = (e) => {
        e.preventDefault();
        setSuccessful(false);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            dispatch(register(firstName, lastName, email, password))
                .then(() => {
                    setSuccessful(true);
                })
                .catch(() => {
                    setSuccessful(false);
                });
        }
    };

    const navigateToLogin = () => {
        if (successful) {
            history.push('/login');
            window.location.reload();
        }
    }

    const toLogin = () => {
        history.push('/login');
        window.location.reload();
    }

    return (
        <div className="container w-full mx-auto text-center register-page flex md:items-center justify-center">
            <div className="mobile ">
                <svg width="1000" height="800" viewBox="100 0 200 500" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <linearGradient id="PSgrad_0" x1="256.331" y1="-31.8848" x2="390.944" y2="477.838"
                                    gradientUnits="userSpaceOnUse">
                        <stop offset="0.0104167" stopColor="#FD880F"/>
                        <stop offset="0.489987" stopColor="#E93322"/>
                        <stop offset="1" stopColor="#9E1FCD"/>
                    </linearGradient>
                    <path fill="url(#PSgrad_0)">
                        <animate attributeName='d'
                                 values='
                                M 374.835 507.344C286.972 572.375 259.886 529.612 172.228 496.229C107.874 471.72 -8.27492 474.27 -37.0412 374.356C-65.8074 274.443 -31.5624 50.2571 89.0793 17.9312C209.721 -14.3947 256.825 -14.2785 290.214 110.329C323.602 234.937 564.245 376.251 374.835 507.344Z;
                                M 842,346.9c52,78,12.8,293.2-125,355c-101.2,45.4-360.7,36.3-439-69c-110-148-315.8-246.5-215-420  C163.5,38.7,249.8,42.3,467,26.9C805,2.9,748.9,207.2,842,346.9z
                            ;
                            M 374.835 507.344C286.972 572.375 259.886 529.612 172.228 496.229C107.874 471.72 -8.27492
                            474.27 -37.0412 374.356C-65.8074 274.443 -31.5624 50.2571 89.0793 17.9312C209.721 -14.3947
                            256.825 -14.2785 290.214 110.329C323.602 234.937 564.245 376.251 374.835 507.344Z;'
                                 keyTimes="0; 0.5; 1" dur="15s" repeatCount="indefinite"

                        />
                    </path>
                </svg>
            </div>
            <div className=" wrapper">
                <svg width="
                            1000" height="1200" viewBox="0 0 800 900" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <linearGradient id="PSgrad_1" x1="160" y1="-69.9999" x2="893.567" y2="357.057"
                                    gradientUnits="userSpaceOnUse">
                        <stop offset="0.0104167" stopColor="#FD880F"/>
                        <stop offset="0.489987" stopColor="#E93322"/>
                        <stop offset="1" stopColor="#9E1FCD"/>
                    </linearGradient>
                    <path fill="url(#PSgrad_1)">
                        <animate attributeName='d'
                                 values='
                               M908.82 408.989C882.826 583.057 803.305 565.209 665.5 627C564.331 672.364 435 807.5 288.5 726.5C142 645.5 -74.2451 351.28 26.3014 177.128C126.848 2.97684 180.607 -50.5181 360.484 53.3335C540.36 157.185 975.214 44.1012 908.82 408.989Z;
                                M842,346.9c52,78,12.8,293.2-125,355c-101.2,45.4-360.7,36.3-439-69c-110-148-315.8-246.5-215-420  C163.5,38.7,249.8,42.3,467,26.9C805,2.9,748.9,207.2,842,346.9z;
                             M908.82 408.989C882.826 583.057 803.305 565.209 665.5 627C564.331 672.364 435 807.5 288.5 726.5C142 645.5 -74.2451 351.28 26.3014 177.128C126.848 2.97684 180.607 -50.5181 360.484 53.3335C540.36 157.185 975.214 44.1012 908.82 408.989Z;'
                                 keyTimes="0; 0.5; 1" dur="15s" repeatCount="indefinite"

                        />
                    </path>
                </svg>
            </div>
            <div className="w-full flex mt-12 md:-mt-6 md:items-center justify-center">
                <Form onSubmit={handleRegister} ref={form} className='w-full flex md:items-center justify-center'>
                    {!successful && (
                        <div className='md:w-1/3 mx-auto'>
                            <h3 className='uppercase text-2xl text-white font-bold mb-6'>Sign up</h3>

                            <div className="items-center justify-center w-full mb-6">
                                <fieldset className='border border-white'>
                                    <legend className='text-left ml-4 px-3 text-white uppercase'>First Name</legend>
                                    <Input
                                        type=" text"
                                        className=" form-control custom-input m-1 text-white"
                                        name="first_name"
                                        value={firstName}
                                        onChange={onChangeFirstName}
                                        validations={[required]}
                                    />

                                </fieldset>
                            </div>
                            <div className="items-center justify-center w-full mb-6">
                                <fieldset className='border border-white'>
                                    <legend className='text-left ml-4 px-3 text-white uppercase'>Last Name</legend>
                                    <Input
                                        type=" text"
                                        className=" form-control custom-input m-1 text-white"
                                        name="last_name"
                                        value={lastName}
                                        onChange={onChangeLastName}
                                        validations={[required]}
                                    />

                                </fieldset>
                            </div>
                            <div className="items-center justify-center w-full">
                                <fieldset className='border border-white'>
                                    <legend className='text-left ml-4 px-3 text-white uppercase'>Email address</legend>
                                    <Input
                                        type=" text"
                                        className=" form-control custom-input m-1 text-white"
                                        name="email"
                                        value={email}
                                        onChange={onChangeEmail}
                                        validations={[required, validEmail]}
                                    />

                                </fieldset>
                            </div>

                            <div className="">
                                <fieldset className='border border-white my-5'>
                                    <legend className='text-left ml-4 px-3 text-white uppercase'>password</legend>
                                    <div className="relative">
                                        <Input
                                            type={passwordShown ? 'text' : 'password'}
                                            className=" form-control custom-input m-1 text-white"
                                            name="password"
                                            value={password}
                                            onChange={onChangePassword}
                                            validations={[required, vpassword]}
                                        /> <FontAwesomeIcon
                                        icon={passwordShown ? solid('eye') : solid('eye-slash')}
                                        color='white'
                                        className='absolute right-4 inset-y-1/4'
                                        onClick={() => {
                                            setPasswordShown(!passwordShown)
                                        }}/>

                                    </div>

                                </fieldset>
                            </div>
                            <div className="mb-6">
                                <fieldset className='border border-white'>
                                    <legend className='text-left ml-4 px-3 text-white uppercase'>repeat Password
                                    </legend>
                                    <div className="relative">
                                        <Input
                                            type={passwordConfirmShown ? 'text' : 'password'}
                                            className=" form-control custom-input  m-2 text-white"
                                            name="password_confirmation"
                                            value={passwordConfirmation}
                                            onChange={onChangePasswordConfirmation}
                                            validations={[required, vpasswordConfirm]}
                                        />
                                        <FontAwesomeIcon icon={passwordConfirmShown ? solid('eye') : solid('eye-slash')}
                                                         className='absolute right-4 inset-y-1/4'
                                                         color='white'
                                                         onClick={() => {
                                                             setPasswordConfirmShown(!passwordConfirmShown)
                                                         }}/>
                                    </div>
                                </fieldset>
                            </div>
                            <div className="form-group mb-6">
                                <Button className="btn btn-primary btn-block" text="Sign up" class='small'
                                        click={() => {
                                            console.log(' login attempt')
                                        }}>
                                </Button>
                            </div>

                            <h4 className="text-white text uppercase border-t border-[#ffffff40] pt-6">
                                Already have an Account? <strong onClick={
                                toLogin
                            } className='cursor-pointer'>Log In</strong>.
                            </h4>
                        </div>
                    )}
                    {message && (
                        <div className=" form-group">
                            <div
                                className={successful ? "text-xl alert text-white uppercase font-bold" : " alert alert-danger"}>
                                {successful ?
                                    <div className='flex flex-col items-center text-white'>
                                        <div className="flex">
                                            <FontAwesomeIcon
                                                icon={solid("check")}
                                                className='p-5 bg-orange-400 rounded-full w-fit font-bold mb-6 '/>
                                        </div>

                                        < h3
                                            className='text-white mb-16'>you’ve
                                            registered
                                            successfully</h3>
                                        <Button text='login' click={navigateToLogin} class='small'/>
                                    </div> : message}
                            </div>

                        </div>
                    )}
                    <CheckButton style={{display: " none"}} ref={checkBtn}/>
                </Form>
            </div>
        </div>
    );
};
export default Register;
import axios from "axios";
import authHeader from "./auth-header";
import qs from "qs";

const API_URL = "https://3dcolor-be.skvad.live/api/v1/";
const getProducts = async () => {
    return await axios.post(API_URL + "products", {}, {headers: authHeader()});
};
const getProductDetails = (slug) => {
    return axios.get(API_URL + "products-by-slug/" + slug, {headers: authHeader()});
};
const showProduct = (id) => {
    return axios.get(API_URL + 'products/' + id, {headers: authHeader()});
}
const getProductFilters = () => {
    return axios.get(API_URL + "product-filters", {headers: authHeader()});
};

const queryProducts = (filter) => {
    return axios({
        method: 'post', url: API_URL + "products",
        data: filter,
        headers: authHeader(),
    })
}

const addToCart = (cart_items) => {
    return axios.post(API_URL + "add-to-cart", {cart_items}, {headers: authHeader()})
}

const modifyCart = (cart_id, cart_items) => {
    return axios.post(API_URL + "modify-cart-items/" + cart_id, {cart_items: cart_items}, {headers: authHeader()})
}

const getAddresses = () => {
    return axios.get(API_URL + "get-customer-addresses", {headers: authHeader()})
}
const saveAddress = (company_name, first_name, last_name, address, address2, city, state, zipcode, phone, special_instructions) => {
    return axios.post(API_URL + "save-customer-address", {
        company_name, first_name, last_name, address, address2, city, state, zipcode, phone, special_instructions
    }, {headers: authHeader()})
};

const getAddress = (address_id) => {
    return axios.get(API_URL + "get-customer-address/" + address_id, {headers: authHeader()})
};

const updateAddress = (address_id, company_name, first_name, last_name, address, address2, city, state, zipcode, phone, special_instructions) => {
    return axios.put(API_URL + "update-customer-address/" + address_id, {
        company_name,
        first_name,
        last_name,
        address,
        address2,
        city,
        state,
        zipcode,
        phone,
        special_instructions
    }, {headers: authHeader()})
}
const checkOut = (cart_id) => {
    return axios.post(API_URL + "checkout/" + cart_id, {}, {headers: authHeader()})
}

const setOrderAddress = (order_id, address_id) => {
    return axios.post(API_URL + "set-delivery-address/" + order_id, {delivery_address_id: address_id}, {headers: authHeader()})
};
const setOrderDeliveryDate = (order_id, date) => {
    return axios.post(API_URL + "set-delivery-date/" + order_id, {estimated_delivery: date}, {headers: authHeader()})
};

const getOrders = () => {
    return axios.get(API_URL + "orders", {headers: authHeader()})
};
const getOrder = (order_id) => {
    return axios.get(API_URL + "get-order/" + order_id, {headers: authHeader()})
}
const completeOrder = (order_id) => {
    return axios.post(API_URL + "complete-order/" + order_id, {}, {headers: authHeader()})
}

export default {
    getProducts,
    queryProducts,
    getProductDetails,
    showProduct,
    getProductFilters,
    addToCart,
    modifyCart,
    getAddresses,
    saveAddress,
    getAddress,
    updateAddress,
    checkOut,
    setOrderAddress,
    setOrderDeliveryDate,
    getOrders,
    getOrder,
    completeOrder
};
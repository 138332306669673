import React, {useEffect, useState} from 'react';
import UserService from "../../services/user.service";
import EventBus from "../../common/EventBus";
import NewAddress from "./Address/NewAddress";
import AddressSelect from "./Address/AddressSelect";
import classes from "./CheckOut.module.scss"
import DateSelect from "./DateSelect";
import Summary from "./Summary";
import ThankYou from "./ThankYou";
import Button from "../../Components/Buttons/Button";
import EditAddress from "./Address/EditAddress";
import CompleteOrder from "./CompleteOrder";

import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const CheckOut = (props) => {
        const [phase, setPhase] = useState(1);
        const [addresses, setAddresses] = useState([]);
        const [activeView, setActiveView] = useState('addressSelect');
        const [isModalVisible, setIsModalVisible] = useState(false);
        const [editAddress, setEditAddress] = useState([]);
        const [isLoading, setIsLoading] = useState(true);
        const [orderID, setOrderID] = useState(0);
        const [order, setOrder] = useState([])
        useEffect(() => {
            window.scrollTo(0, 0);
            props.setIsCheckout(true)
            UserService.getAddresses().then(
                response => {
                    response.data.data.length === 0 && setActiveView('newAddress');
                    setAddresses(response.data.data)
                    setIsLoading(false)
                },
                error => {
                    console.log(error);
                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                });
            UserService.getOrders().then(response => {
                    setOrderID(response.data.data.at(-1).id);
                    setOrder(response.data.data.at(-1))
                },
                error => {
                    console.log(error);
                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                });
        }, []);

        const showModal = () => {
            setIsModalVisible(true)
        }
        const getAddresses = () => {
            return addresses.map(address => <div
                className='flex flex-col md:flex-row w-full justify-between pb-5 border-b'>
                <div className="flex flex-col text-left pb-10 md:pb-0 pt-3">
                    <div className="flex w-full justify-between"><strong
                        className='mb-8'> {address.first_name} {address.last_name}</strong>
                        <FontAwesomeIcon
                            icon={solid('pencil')}
                            color='black'
                            className='cursor-pointer md:hidden'
                            onClick={() => {
                                UserService.getAddress(address.id).then(response => {
                                    setEditAddress(response.data.data);
                                    setIsModalVisible(false);
                                    setActiveView('editAddress')
                                });
                            }}/></div>
                    <div>{address.company_name}</div>
                    <div>{address.address} {address.address2}</div>
                    <div>{address.city}, {address.state} {address.zipcode}</div>
                </div>
                <div className="flex flex-col items-center md:items-end">
                    <div className="hidden md:block pt-4">
                        <Button text={'select'} class={'ghost-sm'} click={() => {
                            UserService.setOrderAddress(orderID, address.id).then(response => {
                                setIsModalVisible(false);
                                setActiveView('dateSelect')
                            })
                        }}/>
                    </div>
                    <div className="md:hidden">
                        <Button text={'select'} class={'ghost'} click={() => {
                            UserService.setOrderAddress(orderID, address.id).then(response => {
                                setIsModalVisible(false);
                                setActiveView('dateSelect')
                            })
                        }}/>
                    </div>

                    <FontAwesomeIcon
                        icon={solid('pencil')}
                        color='black'
                        className='cursor-pointer hidden md:block mt-5'
                        onClick={() => {
                            UserService.getAddress(address.id).then(response => {
                                setEditAddress(response.data.data);
                                setIsModalVisible(false);
                                setActiveView('editAddress')
                            });
                        }}/>
                </div>
            </div>)
        }

        const views = {
            'newAddress': <NewAddress setActiveView={setActiveView}/>,
            'addressSelect': <AddressSelect setActiveView={setActiveView} showModal={showModal} addresses={addresses}
                                            setIsModalVisible={setIsModalVisible} orderID={orderID} setPhase={setPhase}/>,
            'editAddress': <EditAddress setActiveView={setActiveView} editAddress={editAddress}
                                        setIsModalVisible={setIsModalVisible} setAddresses={setAddresses}/>,
            'dateSelect': <DateSelect setActiveView={setActiveView} order={order} setOrder={setOrder} orderID={orderID}
                                      setPhase={setPhase}/>,
            'complete': <CompleteOrder setActiveView={setActiveView} order={order} orderID={orderID} setPhase={setPhase}/>,
            'summary': <Summary setActiveView={setActiveView} order={order} orderID={orderID}/>,
            'thanks': <ThankYou setActiveView={setActiveView} orderID={orderID} setOrder={setOrder}/>
        }


        return (
            <div>

                {(activeView !== 'thanks' && activeView !== 'summary') && <div className='flex justify-center'>
                    <div className="w-full px-5 md:w-2/3 flex overflow-hidden justify-between relative mt-8 px-5">
                        <div
                            className={(phase >= 1) ? classes['active'] + " flex flex-col items-center cursor-pointer" : '' + " flex flex-col items-center"}
                            onClick={() => {
                                phase > 1 && setActiveView('addressSelect')
                            }
                            }>
                            <div
                                className={(phase > 1) ? classes['completed'] + ' ' + classes["circle"] : classes['circle']}>
                                {(phase > 1) ? <FontAwesomeIcon
                                    icon={solid("check")}
                                    className='rounded-full w-fit font-bold h-5' color='white'/> : 1}
                            </div>
                            <div className={"uppercase text-xs md:text-sm mt-4"}>Shipping</div>
                        </div>

                        <div
                            className={(phase >= 2) ? classes['active'] + " flex flex-col items-center cursor-pointer" : '' + " flex flex-col items-center"}
                            onClick={() => {
                                phase > 2 && setActiveView('dateSelect')
                            }}>
                            <div
                                className={(phase > 2) ? classes['completed'] + ' ' + classes["circle"] : classes['circle']}>
                                {(phase > 2) ? <FontAwesomeIcon
                                    icon={solid("check")}
                                    className='rounded-full w-fit font-bold h-5' color='white'/> : 2}
                            </div>
                            <div className="uppercase text-xs md:text-sm mt-4">Date of delivery</div>
                        </div>
                        <div
                            className={(phase >= 3) ? classes['active'] + " flex flex-col items-center cursor-pointer" : '' + " flex flex-col items-center"}>
                            <div
                                className={(phase > 3) ? classes['completed'] + ' ' + classes["circle"] : classes['circle']}>
                                {(phase > 3) ? <FontAwesomeIcon
                                    icon={solid("check")}
                                    className='rounded-full w-fit font-bold h-5' color='white'/> : 3}
                            </div>
                            <div className="uppercase text-xs md:text-sm mt-4">Summary</div>
                        </div>
                        <div className={classes['dash']}/>
                    </div>
                    <div className={classes['left-side-item'] + ' hidden md:block absolute left-0 top-[150px]'}></div>
                    <div className={classes['right-side-item'] + ' hidden md:block absolute right-0 top-[150px]'}></div>
                </div>}


                {
                    !isLoading &&
                    views[activeView]
                }
                {
                    isModalVisible &&
                    <div tabIndex="-1"
                         className="block overflow-y-auto w-full overflow-x-hidden fixed top-0 bottom-0 bg-gray-500/80 right-0 left-0 z-50 md:inset-0 h-modal flex justify-center items-center md:h-full">
                        <div
                            className="relative md:p-8 w-full h-full md:h-auto md:flex items-center justify-center">
                            <div
                                className="relative bg-white h-full md:w-[700px] shadow-xl dark:bg-gray-700">
                                <button type="button" onClick={() => setIsModalVisible(false)}
                                        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                                        data-modal-toggle="popup-modal">
                                    <svg className="hidden md:block w-5 h-5" fill="currentColor"
                                         viewBox="0 0 20 20"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        />
                                    </svg>
                                </button>
                                <div className="p-6 text-center">
                                    <h3 className="mb-5 text-2xl uppercase font-bold font-normal dark:text-gray-400">SELECT
                                        A
                                        DELIVERY
                                        LOCATION</h3>
                                    {getAddresses()}
                                    <div className="flex mt-10 justify-center">
                                        <Button text={'add new address'} class={'rainbow'} click={() => {
                                            setIsModalVisible(false);
                                            setActiveView('newAddress')
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
            ;
    }
;

export default CheckOut;
import React, {useEffect, useState} from 'react';
import Button from "../../../Components/Buttons/Button";
import UserService from "../../../services/user.service";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {history} from "../../../helpers/history";

const AddressSelect = (props) => {
    const [address] = useState(props.addresses.at(-1));
    useEffect(() => {
        props.setPhase(1)
    }, []);
    return (
        <div>
            <div className='mx-auto md:w-2/3 mt-8 px-5'>
                <h3 className='uppercase text-center text-2xl font-bold mb-20'>Delivery address</h3>
                <div className='flex w-full justify-between pb-10'>
                    <div className="flex flex-col text-left"><strong
                        className='mb-8'> {address.first_name} {address.last_name}</strong>
                        <div>{address.company_name}</div>
                        <div>{address.address} {address.address2}</div>
                        <div>{address.city}, {address.state} {address.zipcode}</div>
                    </div>
                    <div className="flex flex-col">
                        <FontAwesomeIcon
                            icon={solid('pencil')}
                            color='black'
                            className='cursor-pointer'
                            onClick={() => {
                                props.setIsModalVisible(true);
                            }}
                        />
                    </div>
                </div>
                <div className="flex flex-col items-center md:flex-row md:justify-between gap-5">
                    <Button text='back to cart' class='ghost' click={() => {
                        history.push("/cart")
                        window.location.reload();
                    }}/>
                    <Button text='continue' click={() => {
                        UserService.setOrderAddress(props.orderID, address.id).then(response => {
                            props.setActiveView('dateSelect')
                        })
                    }} class='rainbow'/>
                </div>

            </div>
        </div>
    );
};

export default AddressSelect;